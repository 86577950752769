// Certifico que o charset está definido corretamente
@charset "UTF-8";

// Variáveis
$primary-color: #E02C2C;
$secondary-color: #999;

// Foundation
// @to-do analisar quais componentes foundation são mesmo utilizados e
// alterar importação do framework para componente por componente
@import "foundation";

// Mixins
@import "mixins";

html,
body {
    height: 100%;
}

.form__content {
  input[type=text]:-webkit-autofill, input[type=password]:-webkit-autofill,
  input[type=text]:-webkit-autofill:focus, input[type=password]:-webkit-autofill:focus,
  input[type=text]:-webkit-autofill:hover, input[type=password]:-webkit-autofill:hover {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0 0 0px 1000px #fff inset !important; }
}

.mb-1 {
  margin-bottom: 1rem !important;
}

#service-login-index {
    background: url("/images/bg-login-unicarioca.jpg") center center no-repeat;
    background-size: cover;
    background-position-y: 80px;
    padding-top: 80px;

    .maintenance-alert {
        @include alert();
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        position: absolute;
        margin-bottom: 0;
    }

    .navbar {
      position: fixed;
      top: 0;
      left: 0;
      height: 80px;
      padding: 5px;
      -webkit-box-shadow: unset;
      box-shadow: unset;
      .nav-wrapper {
        display: flex;
        align-items: center;
      }

    h1 {
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: 190px;
      display: inline-block;
      a.logo {
        display: block;
        background-image: url("/images/logo-unicarioca.png");
        background-repeat: no-repeat;
        background-position: 50% 40%;
        height: 80px;
        background-size: 160px;
      } 
    }
  }

    .content-body {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .wrapper-form {
      display: flex;
      justify-content: center;
      position: relative;
    }
    .form {
      margin-left: initial;
      .input-field input + label {
        color: $primary-color;
        font-size: .9rem;
      }
      /* label focus color */
      .input-field input:focus + label {
        color: $primary-color;
      }
      /* label underline focus color */
      .input-field input:focus {
        border-bottom: 1px solid $primary-color;
        box-shadow: 0 1px 0 0 $primary-color;
      }
         /* valid color */
      .input-field input.valid {
        border-bottom: 1px solid $primary-color;
        box-shadow: 0 1px 0 0 $primary-color;
      }
      background-color: #fff;
      border-radius: 10px;
      max-width: 380px;
        &__title {
          background: #eee;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          h2 {
            font-size: 1.5rem;
            text-align: center;
            color: #949494;
            margin: 20px 0;
          }
        }
        &__content {
          padding: 15px 25px 25px 25px;
        }
        &__button {
          width: 100%;
          input {
            color: #fff;
            width: 100%;
            cursor: pointer;
            font-weight: bold;
          }
        }
        &__footer {
          text-align: center;
          p {
            margin-bottom: 0;
          }
          span {
            color: #909090;
            font-size: 11px;
          }
        }
    }

    .msg-alert {
      padding: 15px;
      strong {
        font-weight: 600;
      }
    }
}

@media only screen and (max-width: #{$small-screen}) {
  #service-login-index {
    h1 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

.g-recaptcha {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 375px) {
  .g-recaptcha {
    transform: scale(0.85);  
  }
}

@media only screen and (max-width: 340px) {
  .g-recaptcha {
    transform: scale(0.7);
  }
}